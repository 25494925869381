'use strict';

(function (snapOnlineAdmin, $) {
    // ----- Generic helpers (used across several tabs) -----

    // Called when a row in one of the Grids is selected.
    snapOnlineAdmin.onGridRowSelected = function (entityName) {
        var grid = $('#grid').data('kendoGrid');
        var selectedItem = grid.dataItem(grid.select());
        $('#detail').load('./' + entityName + '/Detail/' + selectedItem[entityName + 'Id']);
    };

    // ----- Orders tab -----

    // Called when the Vendor is changed in the Create Order page.
    snapOnlineAdmin.onCreateOrderVendorChanged = function () {
        $('#products').load('./Order/ProductsByVendor/' + $('#vendor').find(':selected').val());
    };

    // Provides a custom Date-picker for the grid on the Orders / Import Orders pages.
    snapOnlineAdmin.gridDateColumnFilter = function (element) {
        element.kendoDatePicker({
            format: $('meta[name="sol-date-format"]').attr('value')
        });
    };
}(window.SnapOnlineAdmin = window.SnapOnlineAdmin || {}, jQuery));
